import React from "react";

const Services = ({ classicHeader, darkTheme }) => {
  // const handleClick = () => {
  //   console.log("lol")
  //   // Open the specified URL in a new tab when the div is clicked
  //   const newTab = window.open('https://sci-fi.shaiksameeruddin.com/', '_blank');
  //   if (newTab) {
  //     newTab.focus();
  //   }
  // };


  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}
      
    >
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5" >
          <h2
          
            className={
              "text-24  text-uppercase fw-800 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Sci-Fi
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            World
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        {/* <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="featured-box style-3 mb-5">
                      <div
                        className={
                          "featured-box-icon text-primary  shadow-sm rounded " +
                          (darkTheme ? "bg-dark-1" : "bg-white")
                        }
                      >
                        <i className={service.icon} />
                      </div>
                      <h3 className={darkTheme ? "text-white" : ""}>
                        {service.name}
                      </h3>
                      <p
                        className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
                      >
                        {service.desc}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div> */}
        <div style={{ cursor: 'pointer' }}>
          <h2  className={
              "text-9  fw-600 text-white position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "texts-white" : "text-dark")
            }>
        Journey Through the Stars:  <span className="text-primary" >   The Sci-Fi World </span>
          </h2>
          <br>
          </br>
          <br></br>
          <h4   className={
              "text-6  text-uppercase fw-600 w-100 mb-0 " +
            "text-white  opacity-1"
            }>
          Welcome to the universe of Shaik Sameeruddin, where science fiction is not just a genre but a portal to unimaginable worlds. 

          </h4>
<br></br>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
